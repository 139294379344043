import React, { useState, useContext, useMemo, useEffect } from "react";
import axios from "axios";
import "./allInstitutions.css";
import AllInstitutions from "./AllInstitutions";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useNavigate } from "react-router-dom";
import "./filterPage.css";
import { Accordion } from "react-bootstrap";
import { FiFilter } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { AuthContext } from "../../App";
import config from "../../Boot/config.json";
import { Helmet } from "react-helmet";

function FilterComponent() {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [clearSubmit, setClearSubmit] = useState(false);
  const [dellStatus, setDellStatus] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [paginationStat, setPaginationStat] = useState(false);
  const { setFilterData, filterData } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    typeOfInstitute: "",
    states: [],
    countries: [],
    typeOfColleges: [],
    typeOfSchools: [],
    collegeStatus: filterData.typeOfInstitutes === "College",
    schoolStatus: filterData.typeOfInstitutes === "School",
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

  const [Pages, setPages] = useState({
    totalPages: 1,
    currentPage: 1,
  });
  const [searchQuery, setSearchQuery] = useState("");

  const [typeOfInstitute, setTypeOfInstitution] = useState([
    "School",
    "College",
    "University",
  ]);

  const [institutions, setInstitutions] = useState([]);
  const [filters, setFilters] = useState({
    states: [],
    countries: [],
    typeOfColleges: [],
    typeOfSchools: [],
    collegeStatus: filterData.typeOfInstitutes === "College",
    schoolStatus: filterData.typeOfInstitutes === "School",
  });
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleInstituteChange = (e) => {
    const { id } = e.target;

    if (isMobile) {
      setFormData((prevFormData) => {
        const updatedTypeOfInstitute = id;
        const updatedFilters = {
          ...filters,
          schoolStatus: id === "School",
          collegeStatus: id === "College",
        };

        setFilters(updatedFilters);

        let updatedFormData = {
          ...prevFormData,
          typeOfInstitute: updatedTypeOfInstitute,
        };

        if (!updatedFilters.schoolStatus) {
          updatedFormData = {
            ...updatedFormData,
            typeOfSchools: [],
          };
        }

        if (!updatedFilters.collegeStatus) {
          updatedFormData = {
            ...updatedFormData,
            typeOfColleges: [],
          };
        }

        return updatedFormData;
      });
    } else {
      setFilterData((prevFormData) => {
        const updatedTypeOfInstitute = id;
        const updatedFilters = {
          ...filters,
          schoolStatus: id === "School",
          collegeStatus: id === "College",
        };

        setFilters(updatedFilters);

        let updatedFormData = {
          ...prevFormData,
          typeOfInstitutes: updatedTypeOfInstitute,
        };

        if (!updatedFilters.schoolStatus) {
          updatedFormData = {
            ...updatedFormData,
            typeOfSchools: [],
          };
        }

        if (!updatedFilters.collegeStatus) {
          updatedFormData = {
            ...updatedFormData,
            typeOfColleges: [],
          };
        }

        return updatedFormData;
      });
      setPaginationStat(false);
    }
  };

  const handleCollegeTypeChange = (e) => {
    const { id, checked } = e.target;
    if (isMobile) {
      setFormData((prevFormData) => {
        const collegeTypeArray = Array.isArray(prevFormData.typeOfColleges)
          ? prevFormData.typeOfColleges
          : [];

        return {
          ...prevFormData,
          typeOfColleges: checked
            ? [...collegeTypeArray, id]
            : collegeTypeArray.filter((c) => c !== id),
        };
      });
    } else {
      setFilterData((prevFormData) => {
        const collegeTypeArray = Array.isArray(prevFormData.typeOfColleges)
          ? prevFormData.typeOfColleges
          : [];

        return {
          ...prevFormData,
          typeOfColleges: checked
            ? [...collegeTypeArray, id]
            : collegeTypeArray.filter((c) => c !== id),
        };
      });
      setPaginationStat(false);
    }
  };
  const handleSchoolTypeChange = (e) => {
    const { id, checked } = e.target;
    if (isMobile) {
      setFormData((prevFormData) => {
        const schoolTypeArray = Array.isArray(prevFormData.typeOfSchools)
          ? prevFormData.typeOfSchools
          : [];

        return {
          ...prevFormData,
          typeOfSchools: checked
            ? [...schoolTypeArray, id]
            : schoolTypeArray.filter((c) => c !== id),
        };
      });
    } else {
      setFilterData((prevFormData) => {
        const schoolTypeArray = Array.isArray(prevFormData.typeOfSchools)
          ? prevFormData.typeOfSchools
          : [];

        return {
          ...prevFormData,
          typeOfSchools: checked
            ? [...schoolTypeArray, id]
            : schoolTypeArray.filter((c) => c !== id),
        };
      });
      setPaginationStat(false);
    }
  };
  const handleStateChange = (e) => {
    const { id, checked } = e.target;
    if (isMobile) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        states: checked
          ? [...prevFormData.states, id]
          : prevFormData.states.filter((c) => c !== id),
      }));
    } else {
      setFilterData((prevFormData) => ({
        ...prevFormData,
        states: checked
          ? [...prevFormData.states, id]
          : prevFormData.states.filter((c) => c !== id),
      }));
      setPaginationStat(false);
    }
  };
  const handleCountryChange = (e) => {
    const { id, checked } = e.target;
    if (isMobile) {
      setFormData((prevFormData) => {
        const countriesArray = Array.isArray(prevFormData.countries)
          ? prevFormData.countries
          : [];

        return {
          ...prevFormData,
          countries: checked
            ? [...countriesArray, id]
            : countriesArray.filter((c) => c !== id),
        };
      });
    } else {
      setFilterData((prev) => {
        const countriesArray = Array.isArray(prev.countries)
          ? prev.countries
          : [];

        return {
          ...prev,
          countries: checked
            ? [...countriesArray, id]
            : countriesArray.filter((c) => c !== id),
        };
      });
      setPaginationStat(false);
    }
  };
  const handleSearchQuery = (query) => {
    setSearchQuery(query);
  };

  const handleFiltersSubmit = () => {
    setFilterData({
      typeOfInstitutes: formData.typeOfInstitute,
      states: formData.states,
      countries: formData.countries,
      typeOfColleges: formData.typeOfColleges,
      typeOfSchools: formData.typeOfSchools,
      collegeStatus: formData.typeOfInstitute === "College",
      schoolStatus: formData.typeOfInstitute === "School",
    });
    // setFilterSubmit(true);
  };

  const handleClearFilter = () => {
    setClearSubmit(true);
    setPageNumber(1);

    setFilterData({
      typeOfInstitutes: "",
      states: [],
      countries: [],
      typeOfColleges: [],
      typeOfSchools: [],
    });
    setFilters({ ...filters, collegeStatus: false, schoolStatus: false });

    setFormData({
      typeOfInstitute: "",
      states: [],
      countries: [],
      typeOfColleges: [],
      typeOfSchools: [],
      collegeStatus: false,
      schoolStatus: false,
    });
  };

  const handleDeleteInstitute = () => {
    setDellStatus((dellStatus) => !dellStatus);
  };

  const handlePaginationChange = (pageNumber) => {
    setPageNumber(pageNumber);
    setPaginationStat(true);
  };

  function urlEncoded(title) {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  }
  useEffect(() => {
    axios
      .get(`${config.baseUrl}/Universities/getFormDetails`)
      .then((res) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          states: res.data.statesInIndia,
          countries: res.data.countries,
          typeOfColleges: res.data.typeOfColleges,
          typeOfSchools: res.data.typeOfSchools,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    console.log({
      clearSubmit,
      dellStatus,
      pageNumber,
      searchQuery,
      filterData,
    });
    if (!paginationStat) {
      setPageNumber(1);
    }
    if (searchQuery.length !== 0) {
      if (paginationStat) {
        setPageNumber(pageNumber);
      }
    }
    axios
      .get(`${config.baseUrl}/Universities/getUniversities`, {
        params: {
          typeOfInstitute: filterData.typeOfInstitutes,
          states: filterData.states.toString(),
          typeOfCollege: filterData.typeOfColleges.toString(),
          typeOfSchool: filterData.typeOfSchools.toString(),
          country: filterData.countries.toString(),
          pageNumber: pageNumber,
          searchQuery: searchQuery,
        },
      })
      .then((res) => {
        setShow(false);
        setInstitutions(res.data.data);
        setPages({
          totalPages: res.data.totalPages,
          currentPage: res.data.currentPage,
        });
        // setFilterSubmit(false);
        setClearSubmit(false);
        setPaginationStat(false);
        // setFilters({ ...filters, searchQuery: "" });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [clearSubmit, dellStatus, pageNumber, searchQuery, filterData]);

  useEffect(() => {
    const encodedPath = "/institutions";
    const params = [];

    const addParam = (key, value) => {
      if (value) {
        params.push(`${key}=${urlEncoded(value)}`);
      }
    };

    const addArrayParam = (key, array) => {
      if (array && array.length > 0) {
        const encodedArray = array.map((item) => urlEncoded(item)).join(",");
        params.push(`${key}=${encodedArray}`);
      }
    };

    addParam("typeOfInstitutes", filterData.typeOfInstitutes);
    addArrayParam("typeOfSchools", filterData.typeOfSchools);
    addArrayParam("typeOfColleges", filterData.typeOfColleges);
    addArrayParam("countries", filterData.countries);
    addArrayParam("states", filterData.states);

    const finalPath = `${encodedPath}${
      params.length ? `?${params.join("&")}` : ""
    }`;
    navigate(finalPath);
  }, [filterData, navigate]);

  const memorizedStates = useMemo(() => filters.states, [filters.states]);
  const memorizedCountries = useMemo(
    () => filters.countries,
    [filters.countries]
  );
  const memorizedTypeOfColleges = useMemo(
    () => filters.typeOfColleges,
    [filters.typeOfColleges]
  );
  const memorizedTypeOfSchools = useMemo(
    () => filters.typeOfSchools,
    [filters.typeOfSchools]
  );

  return (
    <div className="institutionsPage">
      <Helmet>
        <title>Vidyauni | All Institutions</title>
      </Helmet>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        name="filter offcanvas"
        className="d-block d-lg-none offCanvasFilter"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter By</Offcanvas.Title>
        </Offcanvas.Header>

        <div className="vertical-tabs-container">
          <div className="vertical-tabs">
            <div
              className={`tab ${activeTab === 0 && "active"}`}
              onClick={() => handleTabClick(0)}
            >
              Type of Institution
            </div>

            {filters.collegeStatus === true && (
              <div
                className={`tab ${activeTab === 1 && "active"}`}
                onClick={() => handleTabClick(1)}
              >
                Type of College
              </div>
            )}
            {filters.schoolStatus === true && (
              <div
                className={`tab ${activeTab === 2 && "active"}`}
                onClick={() => handleTabClick(2)}
              >
                Type of School
              </div>
            )}

            <div
              className={`tab ${activeTab === 3 && "active"}`}
              onClick={() => handleTabClick(3)}
            >
              Countries
            </div>
            <div
              className={`tab ${activeTab === 4 && "active"}`}
              onClick={() => handleTabClick(4)}
            >
              States
            </div>

            {/* Add more tabs as needed */}
          </div>
          <div className="tab-content p-2">
            {activeTab === 0 && (
              <div>
                {typeOfInstitute.map((institute, index) => (
                  <fieldset className="checkBoxFields" key={index}>
                    <input
                      type="radio"
                      id={institute}
                      name="typeOfInstitute"
                      onChange={handleInstituteChange}
                      className="m-2"
                      checked={formData.typeOfInstitute === institute}
                    />
                    <label htmlFor={institute}>{institute}</label>
                  </fieldset>
                ))}
              </div>
            )}
            {activeTab === 1 && (
              <div>
                {memorizedTypeOfColleges.map((collegeType, index) => (
                  <fieldset className="checkBoxFields" key={index}>
                    <input
                      type="checkbox"
                      id={collegeType}
                      onChange={handleCollegeTypeChange}
                      className="m-2"
                      checked={formData.typeOfColleges.includes(collegeType)}
                    />
                    <label htmlFor={collegeType}>{collegeType}</label>
                  </fieldset>
                ))}
              </div>
            )}
            {activeTab === 2 && (
              <div>
                {memorizedTypeOfSchools.map((schoolType, index) => (
                  <fieldset className="checkBoxFields" key={index}>
                    <input
                      type="checkbox"
                      id={schoolType}
                      onChange={handleSchoolTypeChange}
                      className="m-2"
                      checked={formData.typeOfSchools.includes(schoolType)}
                    />
                    <label htmlFor={schoolType}>{schoolType}</label>
                  </fieldset>
                ))}
              </div>
            )}
            {activeTab === 3 && (
              <div>
                {memorizedCountries.map((country, index) => (
                  <fieldset className="checkBoxFields" key={index}>
                    <input
                      type="checkbox"
                      id={country}
                      onChange={handleCountryChange}
                      className="m-2"
                      checked={formData.countries.includes(country)}
                    />
                    <label htmlFor={country}>{country}</label>
                  </fieldset>
                ))}
              </div>
            )}
            {activeTab === 4 && (
              <div>
                {memorizedStates.map((state, index) => (
                  <fieldset className="checkBoxFields" key={index}>
                    <input
                      type="checkbox"
                      id={state}
                      onChange={handleStateChange}
                      className="m-2"
                      checked={formData.states.includes(state)}
                    />
                    <label htmlFor={state}>{state}</label>
                  </fieldset>
                ))}
              </div>
            )}
          </div>
          <div className="position-absolute bottom-0 d-flex filterButtons w-100">
            <button
              onClick={handleFiltersSubmit}
              className="w-50 py-2 text-white"
            >
              Apply Filters
            </button>
            <button
              className="w-50 py-2 text-white"
              onClick={handleClearFilter}
            >
              Clear Filters
            </button>
          </div>
        </div>
      </Offcanvas>
      <div className="filterSection d-none d-lg-flex flex-column bg-white">
        <div className="bg-white filterHeading p-2 d-flex justify-content-between align-items-center">
          <p className=" fw-bold ms-3">Filter By</p>

          <p
            className="clearAll fw-bold"
            onClick={handleClearFilter}
            title="Clear filters"
          >
            Clear <RxCross2 />
          </p>
        </div>

        <Accordion
          fixed="top"
          defaultActiveKey={["0", "1", "2", "3", "4"]}
          alwaysOpen
          className="accordion-element"
          flush
        >
          <Accordion.Item eventKey="0" className="item">
            <Accordion.Header>Type of Institution</Accordion.Header>
            <Accordion.Body className="accordion-scrollable">
              {typeOfInstitute.map((institute, index) => (
                <fieldset className="checkBoxFields" key={index}>
                  <input
                    type="radio"
                    id={institute}
                    name="typeOfInstitute"
                    onChange={handleInstituteChange}
                    className="m-2"
                    checked={filterData.typeOfInstitutes.includes(institute)}
                  />
                  <label htmlFor={institute}>{institute}</label>
                </fieldset>
              ))}
            </Accordion.Body>
          </Accordion.Item>
          {filters.collegeStatus === true && (
            <Accordion.Item eventKey="1" className="item">
              <Accordion.Header>Type of College</Accordion.Header>
              <Accordion.Body className="accordion-scrollable">
                {memorizedTypeOfColleges.map((collegeType, index) => (
                  <fieldset className="checkBoxFields" key={index}>
                    <input
                      type="checkbox"
                      id={collegeType}
                      onChange={handleCollegeTypeChange}
                      className="m-2"
                      checked={
                        filterData.typeOfColleges &&
                        filterData.typeOfColleges.includes(collegeType)
                      }
                    />
                    <label htmlFor={collegeType}>{collegeType}</label>
                  </fieldset>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          )}
          {filters.schoolStatus === true && (
            <Accordion.Item eventKey="2" className="item">
              <Accordion.Header>Type of School</Accordion.Header>
              <Accordion.Body className="accordion-scrollable">
                {memorizedTypeOfSchools.map((schoolType, index) => (
                  <fieldset className="checkBoxFields" key={index}>
                    <input
                      type="checkbox"
                      id={schoolType}
                      onChange={handleSchoolTypeChange}
                      className="m-2"
                      checked={
                        filterData.typeOfSchools &&
                        filterData.typeOfSchools.includes(schoolType)
                      }
                    />
                    <label htmlFor={schoolType}>{schoolType}</label>
                  </fieldset>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          )}

          <Accordion.Item eventKey="3" className="item">
            <Accordion.Header>Country</Accordion.Header>
            <Accordion.Body className="accordion-scrollable">
              {memorizedCountries.map((country, index) => (
                <fieldset className="checkBoxFields" key={index}>
                  <input
                    type="checkbox"
                    id={country}
                    onChange={handleCountryChange}
                    className="m-2"
                    checked={filterData.countries.includes(country)}
                  />
                  <label htmlFor={country}>{country}</label>
                </fieldset>
              ))}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4" className="item">
            <Accordion.Header>States In India</Accordion.Header>
            <Accordion.Body className="accordion-scrollable">
              {memorizedStates.map((state, index) => (
                <fieldset className="checkBoxFields" key={index}>
                  <input
                    type="checkbox"
                    id={state}
                    onChange={handleStateChange}
                    className="m-2"
                    checked={filterData.states.includes(state)}
                  />
                  <label htmlFor={state}>{state}</label>
                </fieldset>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <AllInstitutions
        data={institutions}
        deleteFn={handleDeleteInstitute}
        paginationFn={handlePaginationChange}
        searchQueryValue={handleSearchQuery}
        totalPages={Pages.totalPages}
        currentPage={Pages.currentPage}
      />
      <button
        onClick={handleShow}
        className="d-lg-none bottom-0 filterButton position-fixed m-3 border-0 rounded-circle p-2"
      >
        <FiFilter className="text-white" />
      </button>
    </div>
  );
}

export default FilterComponent;
